import { useFormik } from "formik";
import { ContentType, UserCoverStyle } from "authory-api-types/dist/enums";
import { useState } from "react";
import { format } from "date-fns";
import * as Yup from "yup"
import { V3PanelWithPreview } from "../V3PanelWithPreview/V3PanelWithPreview"
import { renderArticleDate } from "../../utils/articleDateParser";
import { V3DateValidator, nameValidator, sourceValidator } from "../../utils/validation-utils";
import { V3Button, V3TertiaryButton } from "../Button";
import { V3IconRefresh } from "../Icons";
import { VerticalSpacing } from "../VerticalSpacing/VerticalSpacing";
import { V3TextArea, V3TextInput } from "../TextInput/V3TextInput";
import { V3Select } from "../Select";
import { NotificationInlineLink } from "../ReusableStyledComponents/NotificationInlineLink";
import { ItemPreviewBtnWrapper, ItemPreviewDateInputWrapper, ItemPreviewSourceDateWrapper } from "./ItemPreviewEdit.styles";
import { V3Loader } from "../Loader";
import { V3CaptionGrey35 } from "../ReusableStyledComponents/V3CaptionGrey35";
import { MediaUploadWrapper } from "../MediaUploadWrapper";
import { V3TertiaryCTAColor } from "../Button/V3TertiaryCTA.types";
import { MAX_DESCRIPTION_SIZE } from "../../types/max_description_size";
import { ItemCreatedSourceTooltip, ItemToCreateSourceTooltip } from "../ReusableStyledComponents/ItemCreatedSourceToolip";

export interface ItemPreviewFormik {
    title: string,
    description: string,
    date: string,
    sourceSlug?: string,
    newSource: boolean,
    name?: string,
    customImage?: string,
    style: UserCoverStyle | null,
    resetPreview: boolean,
}

interface ItemPreviewEditProps extends Omit<ItemPreviewFormik, "newSource" | "customImage" | "resetPreview"> {
    sourcesOptions: { value: string, text: string | null }[],
    sourceSlug: string,
    allowCreateNewSource?: boolean,
    previewImage?: string | null,
    newSourcePlaceholder?: string,
    isAddContentFlow?: boolean,
    onNextText: string,
    showAutoImportBanner?: boolean,
    contentType: ContentType,
    onTemporaryImageUploadHandler: (file: File) => Promise<string>,
    onItemPreviewSubmit: (data: ItemPreviewFormik) => Promise<void>,
    mainParagraph?: JSX.Element,
    hidePreviewLabel?: boolean,
    isBulkImport?: boolean,
}

export const ItemPreviewEdit = ({
    title,
    date,
    style,
    sourcesOptions,
    previewImage,
    description,
    allowCreateNewSource = false,
    newSourcePlaceholder,
    isAddContentFlow = false,
    sourceSlug,
    onNextText,
    name,
    showAutoImportBanner = false,
    contentType,
    onTemporaryImageUploadHandler,
    onItemPreviewSubmit,
    hidePreviewLabel,
    mainParagraph,
    isBulkImport = false,
}: ItemPreviewEditProps) => {
    const [actionSubmitting, setActionSubmitting] = useState(false);

    const doesntHaveSources = !sourcesOptions.length;

    const itemPreviewFormik = useFormik<ItemPreviewFormik>({
        initialValues: {
            name,
            sourceSlug,
            newSource: doesntHaveSources,
            title: title || "",
            description: description || "",
            date: !!date ? renderArticleDate(date, "yyyy-MM-dd") : format(new Date(), "yyyy-MM-dd"),
            customImage: previewImage || "",
            style,
            resetPreview: false,
        },
        onSubmit: async (data) => {
            setActionSubmitting(true);

            try {
                await onItemPreviewSubmit(data);
            } catch { }

            setActionSubmitting(false);
        },
        validationSchema: Yup.object().shape({
            title: !isAddContentFlow ? Yup.string() : Yup.string().required("Please add a title"),
            description: !isAddContentFlow || !description.length ? Yup.string() : Yup.string().required("Please add a description").max(MAX_DESCRIPTION_SIZE, "Description must be 250 characters or less"),
            name: !isAddContentFlow ? Yup.string() : nameValidator,
            date: !isAddContentFlow ? Yup.string() : V3DateValidator,
            sourceSlug: !isAddContentFlow ? Yup.string() : sourceValidator,
        }),
        enableReinitialize: true,
    });

    const tooltip = isAddContentFlow ? <ItemToCreateSourceTooltip /> : <ItemCreatedSourceTooltip />;

    const doesntHavePreview = style === "none" || (style === null && !previewImage);

    return <V3PanelWithPreview
        title={itemPreviewFormik.values.title || (isAddContentFlow ? undefined : title)}
        description={itemPreviewFormik.values.description || (isAddContentFlow ? undefined : description)}
        imageSrc={itemPreviewFormik.values.customImage || ""}
        showMediaPreview
        showMetaContent
        date={itemPreviewFormik.values.date}
        contentType={contentType}
        hidePreviewLabel={hidePreviewLabel}
        isBulkImport={isBulkImport}
        sourceName={itemPreviewFormik.values.newSource ? itemPreviewFormik.values.name : (sourcesOptions.find(it => it.value === itemPreviewFormik.values.sourceSlug)?.text || "")}
        mainPanel={<>
            <VerticalSpacing bottom={24}>
                {
                    !!mainParagraph && <VerticalSpacing bottom={36}>{mainParagraph}</VerticalSpacing>
                }
                <MediaUploadWrapper
                    disabled={actionSubmitting}
                    onFileUploadHandler={async (selectorFiles) => {
                        return await onTemporaryImageUploadHandler(selectorFiles[0]);
                    }}
                    onUploadSuccess={(filepath) => {
                        itemPreviewFormik.setFieldValue("customImage", filepath);
                        itemPreviewFormik.setFieldValue("style", UserCoverStyle.image);
                    }}
                    onRemovePreview={doesntHavePreview || isAddContentFlow ? undefined : () => {
                        itemPreviewFormik.setFieldValue("customImage", "");
                        itemPreviewFormik.setFieldValue("style", UserCoverStyle.none);
                    }}
                    hasPreviewImage={!!itemPreviewFormik.values.customImage?.length}
                />
            </VerticalSpacing>
            <form onSubmit={itemPreviewFormik.handleSubmit}>
                <VerticalSpacing bottom={24}>
                    <V3TextInput
                        label="Title"
                        onChange={itemPreviewFormik.handleChange}
                        onBlur={itemPreviewFormik.handleBlur}
                        name="title"
                        id="title"
                        disabled={actionSubmitting}
                        value={itemPreviewFormik.values.title}
                        error={itemPreviewFormik.errors.hasOwnProperty("title") && itemPreviewFormik.touched.hasOwnProperty("title") ? itemPreviewFormik.errors.title : undefined}
                    />
                </VerticalSpacing>
                <VerticalSpacing bottom={24}>
                    <V3TextArea
                        label="Description"
                        onChange={itemPreviewFormik.handleChange}
                        onBlur={itemPreviewFormik.handleBlur}
                        name="description"
                        id="description"
                        disabled={actionSubmitting}
                        value={itemPreviewFormik.values.description}
                        characterLimit={MAX_DESCRIPTION_SIZE}
                        error={itemPreviewFormik.errors.hasOwnProperty("description") && itemPreviewFormik.touched.hasOwnProperty("description") ? itemPreviewFormik.errors.description : undefined}
                    />
                </VerticalSpacing>
                <ItemPreviewSourceDateWrapper>
                    <div>
                        {
                            !itemPreviewFormik.values.newSource
                                ? <V3Select
                                    tooltipContent={tooltip}
                                    tooltipMaxWidth={270}
                                    disabledOption="Please select a source"
                                    name="sourceSlug"
                                    label="Source"
                                    disabled={!isAddContentFlow || actionSubmitting}
                                    placeholder="Select a source"
                                    value={itemPreviewFormik.values.sourceSlug}
                                    onChange={itemPreviewFormik.handleChange}
                                    options={sourcesOptions}
                                    actionText={allowCreateNewSource ? "Content is from new source" : undefined}
                                    actionClickHandler={() => itemPreviewFormik.setFieldValue("newSource", true)}
                                    error={itemPreviewFormik.errors.hasOwnProperty("sourceSlug") && itemPreviewFormik.touched.hasOwnProperty("sourceSlug") ? itemPreviewFormik.errors.sourceSlug : undefined}
                                />
                                : <V3TextInput
                                    tooltipContent={tooltip}
                                    tooltipMaxWidth={isAddContentFlow ? 300 : 255}
                                    label="Source"
                                    name="name"
                                    width={"100%"}
                                    disabled={!isAddContentFlow || actionSubmitting}
                                    placeholder={newSourcePlaceholder}
                                    onChange={itemPreviewFormik.handleChange}
                                    onBlur={itemPreviewFormik.handleBlur}
                                    value={itemPreviewFormik.values.name}
                                    actionText={doesntHaveSources ? undefined : "Content is from existing source"}
                                    actionClickHandler={() => itemPreviewFormik.setFieldValue("newSource", false)}
                                    error={itemPreviewFormik.errors.hasOwnProperty("name") && itemPreviewFormik.touched.hasOwnProperty("name") ? itemPreviewFormik.errors.name : undefined}
                                />
                        }
                        {
                            itemPreviewFormik.values.newSource && showAutoImportBanner && <VerticalSpacing top={12}>
                                <V3CaptionGrey35>Authory will automatically import all future articles with your byline from this website. ✨</V3CaptionGrey35>
                            </VerticalSpacing>
                        }
                    </div>
                    <div>
                        <ItemPreviewDateInputWrapper>
                            <V3TextInput
                                label="Date"
                                tooltipContent={<>All content items in your Authory account are ordered by date by default. You can switch to ordering manually anytime. <NotificationInlineLink href="https://authory.com/help/en/articles/9667492-why-do-all-content-items-in-my-authory-require-a-date" target="_blank">Learn more</NotificationInlineLink></>}
                                tooltipMaxWidth={280}
                                type="date"
                                disabled={actionSubmitting}
                                onChange={itemPreviewFormik.handleChange}
                                onBlur={itemPreviewFormik.handleBlur}
                                name="date"
                                id="date"
                                value={itemPreviewFormik.values.date}
                                error={itemPreviewFormik.errors.hasOwnProperty("date") && itemPreviewFormik.touched.hasOwnProperty("date") ? itemPreviewFormik.errors.date : undefined}
                            />
                        </ItemPreviewDateInputWrapper>
                    </div>
                </ItemPreviewSourceDateWrapper>

            </form>
        </>}
        btnPanel={<VerticalSpacing top={24}>
            <ItemPreviewBtnWrapper>
                <div>
                    {
                        (
                            itemPreviewFormik.dirty
                            && isAddContentFlow
                            && (
                                itemPreviewFormik.initialValues.title !== itemPreviewFormik.values.title
                                ||
                                itemPreviewFormik.initialValues.customImage !== itemPreviewFormik.values.customImage
                                ||
                                itemPreviewFormik.initialValues.description !== itemPreviewFormik.values.description
                            )
                            && (
                                itemPreviewFormik.initialValues.title !== ""
                                ||
                                itemPreviewFormik.initialValues.customImage !== ""
                                ||
                                itemPreviewFormik.initialValues.customImage !== ""
                            )
                        ) && !actionSubmitting && <V3TertiaryButton
                            ctaColor={V3TertiaryCTAColor.gray}
                            icon={<V3IconRefresh />}
                            type="button"
                            text="Revert to default preview"
                            onClick={async () => {
                                if (isAddContentFlow) {
                                    itemPreviewFormik.resetForm();
                                } else {
                                    itemPreviewFormik.setFieldValue("resetPreview", true);
                                    itemPreviewFormik.submitForm();
                                }
                            }}
                        />
                    }
                </div>
                <div>
                    {
                        actionSubmitting ? <V3Loader height={42} /> : <V3Button
                            text={onNextText}
                            type="submit"
                            disabled={actionSubmitting}
                            autoWidth
                            onClick={() => {
                                itemPreviewFormik.submitForm();
                            }}
                        />
                    }
                </div>
            </ItemPreviewBtnWrapper>
        </VerticalSpacing>}
    />
}